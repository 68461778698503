.footer {
  color: white;
  padding: 20px 0;
  min-height: 80px;
  display: flex;
  align-items: flex-end;
}

.footer-content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.footer-phone-icon {
  width: 16px;
  height: 20px;
  margin-right: 10px;
}

.footer p {
  margin: 0;
}

/* Responsive design */
@media (max-width: 768px) {
  .footer {
    padding: 15px 0;
  }

  .footer-content {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 10px 0;
  }
}