:root {
  --plum: #4460aa;
  --orange: #ff8434;
  --dark-blue: #2c3e50;
  --teal: #329199;
  --white: #fff;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: Open Sans, Roboto, Oxygen, Ubuntu, Cantarell, Lato, Helvetica Neue, sans-serif;
  color: var(--dark-blue);
  box-sizing: border-box;
}

body {
  position: relative;
}

input:focus {
  outline: none;
}

.bg-orange {
  background-color: var(--orange);
}

.orange {
  color: var(--orange);
}

.dark-blue {
  background-color: var(--dark-blue);
}

.teal {
  color: var(--teal);
}

.bg-teal {
  background-color: var(--teal);
}

.bg-dark-blue {
  background-color: var(--dark-blue);
}

.plum {
  color: var(--plum);
}

.bg-plum {
  background-color: var(--plum);
}

.white {
  color: var(--white);
}

/* Updated navigation styles */
nav.fixed-top {
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.nav-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--white);
  transition: opacity 0.3s ease, box-shadow 0.3s ease;
}

nav.fixed-top.scrolled .nav-background {
  opacity: .90;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-content {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  width: 100%;
}

.nav-left {
  display: flex;
  align-items: center;
}

.nav-center {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.nav-right {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.logo-container {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  max-width: 30px;
  max-height: 30px;
}

.company-name {
  font-size: 24px;
  margin-left: 15px;
}

.bg-gradient-header {
  background-image: linear-gradient(to bottom right, var(--dark-blue), var(--plum));
}

/* Updated styles for the orange button */
.btn-trial {
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
}

.btn-trial:hover,
.btn-trial:active {
  color: var(--white);
}

.call-button {
  color: white;
  transition: background-color 0.3s ease;
}

.call-button:hover {
  background-color: color-mix(in srgb, black 80%, black) !important;
  color: white;
}

/* Updated styles for the Next button */
.next-button {
  color: var(--white);
  transition: background-color 0.3s ease;
}

.next-button:hover {
  background-color: color-mix(in srgb, var(--contact-button-color) 80%, black) !important;
  color: var(--white);
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Mobile menu styles */
.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 250px;
  background-color: var(--white);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}

.mobile-menu.open {
  display: block;
  transform: translateX(0);
}

.mobile-menu.closed {
  transform: translateX(100%);
}

/* Hamburger icon styles */
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 33, 33, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

/* Hamburger button styles */
.btn-hamburger {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
}

/* Responsive adjustments */
@media (max-width: 1100px) {
  .nav-center {
    display: none;
  }

  .nav-link {
    margin: 10px 0;
  }

  .mobile-menu .btn-trial {
    display: block;
    text-align: center;
    margin-top: 20px;
  }

  .nav-right .btn-hamburger {
    display: block;
  }

  .nav-right .btn-trial {
    display: none;
  }
}

@media (min-width: 1100px) {
  .nav-right .btn-trial {
    display: block;
  }

  .nav-right .btn-hamburger {
    display: none;
  }
}

/* Custom styles for centering content */
@media (min-width: 1200px) {
  .nav-wrapper {
    max-width: 100%;
    padding: 0 30px;
  }

  .container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 15px;
  }
}

.flip {
  transform: rotate(180deg);
}

/* Custom Shape Divider styles */
.custom-shape-divider {
  position: relative;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  margin-bottom: -5px;
  min-width: 5000px;
  margin-left: -1400px;
}

.custom-shape-divider svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

/* New button styles */
.button {
  display: inline-block;
  color: #fff;
  background: linear-gradient(#0000, rgb(0 0 0/40%)) top/100% 800%;
  transition: 0.5s;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.button:hover {
  background-position: bottom;
}