/* Pricing section styles */

.pricing-container {
  display: flex;
  justify-content: center;
}

.sale-ad {
  padding: 2rem 1rem 2rem;
  margin: 2rem 1rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: var(--plum);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}

@media (max-width: 785px) {
  .sale-ad {
    width: 500px;
  }

  .free-trial-banner {
    top: 10px;
    left: -100px;
  }

  .discount-circle {
    left: 0px;
  }

  .discount-duration {
    margin-left: 90px;
  }
}

@media (min-width: 786px) {
  .sale-ad {
    width: 700px;
  }

  .free-trial-banner {
    top: 20px;
    left: -235px;
  }

  .discount-circle {
    left: -40px;
  }

  .discount-duration {
    margin-left: 50px;
  }
}

.sale-ad.dark-blue {
  background-color: var(--plum);
}

.sale-ad.dashed-border {
  outline: 2px dashed var(--white);
  outline-offset: -10px;
}

.free-trial-banner {
  position: absolute;
  width: calc(100% + 20px);
  background: linear-gradient(90deg, #FFD700 0%, #FFF700 50%, #FFD700 100%);
  color: var(--dark-blue);
  padding: 0.5rem;
  font-weight: bold;
  font-size: 1.5rem;
  transform: rotate(-20deg);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  z-index: 20;
}

/* Limited Time Offer styles */
.limited-time-offer {
  position: relative;
  display: inline-block;
  margin: 1.3rem 0 1rem;
  background-color: var(--white);
  width: calc(100% + 32px);
  padding: 4px 0;
}

.limited-time-offer-content {
  margin: 0 auto;
  border-left: 2px dashed var(--dark-blue);
  border-right: 2px dashed var(--dark-blue);
  background-color: var(--white);
  width: calc(100% - 16px);
  margin: 0;
  padding: 4px 0 4px 0;
}

.limited-time-offer h2 {
  color: var(--dark-blue);
  font-size: 2.2rem;
  font-weight: 700;
  position: relative;
  z-index: 2;
  white-space: nowrap;
}

/* Discount info styles */
.discount-info {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.discount-circle {
  position: absolute;
  width: 80px;
  height: 80px;
  background: radial-gradient(circle, var(--orange) 60%, #dd6611 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-15deg);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.discount-circle span {
  color: var(--white);
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
  line-height: 1;
}

.discount-duration {
  font-size: 1.5rem;
  position: relative;
  z-index: 0;
  color: var(--white);
}

/* Pulsing animation for 25% off circle */
@keyframes pulse {
  0% {
    transform: scale(1) rotate(-15deg);
  }

  50% {
    transform: scale(1.05) rotate(-15deg);
  }

  100% {
    transform: scale(1) rotate(-15deg);
  }
}

.pulse-animation {
  animation: pulse 2s infinite ease-in-out;
}

.offer-description {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  max-width: 600px;
  color: var(--white);
}

/* Button styles */
.btn-trial {
  padding: 0.8rem 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: var(--orange);
  color: var(--white);
}

.btn-trial:hover {
  background-color: #e67422;
}