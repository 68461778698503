.contact-section {
  position: relative;
  padding: 160px 0;
  overflow: hidden;
  margin-top: -60px;
}

.contact-card {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

/* .next-button {
  color: white;
}

.next-button:hover {
  background-color: color-mix(in srgb, black 70%, black) !important;
  color: white;
} */

.contact-card h2 {
  margin-bottom: 20px;
  color: var(--dark-blue);
}

.contact-card p {
  margin-bottom: 30px;
  color: var(--dark-blue);
}

.input-group {
  margin-bottom: 20px;
}

.contact-card input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.contact-card input.error {
  border-color: #ff0000;
}

.error-message {
  color: #ff0000;
  font-size: 0.9em;
  margin-top: 5px;
}

.preferred-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.preferred-contact span {
  margin-bottom: 10px;
}

.pill-buttons {
  display: flex;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.pill-button {
  flex: 1;
  padding: 10px;
  border: none;
  background-color: #f0f0f0;
  color: var(--dark-blue);
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 3px inset rgba(0, 0, 0, .2);
}

.pill-button:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pill-button:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pill-button.active {
  background-color: var(--plum);
  color: white;
  box-shadow: none;
}

button {
  width: 100%;
  padding: 10px;
  background-color: var(--plum);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s;
}

button:hover:not(:disabled) {
  background-color: #ccc;
}

button:hover {}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.modal-overlay.visible {
  opacity: 1;
  pointer-events: auto;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  max-width: 90%;
  width: 400px;
  height: auto;
  z-index: 1001;
}

.modal h3 {
  margin-bottom: 20px;
  color: var(--dark-blue);
}

.modal p {
  margin-bottom: 30px;
  color: var(--dark-blue);
}

.modal button {
  padding: 10px 20px;
  background-color: var(--plum);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.background-square {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 1;
}

.square-1 {
  top: 70%;
  right: 0;
  transform: translateY(-50%) rotate(15deg);
  transform-origin: top left;
}

.square-2 {
  top: 70%;
  left: 0;
  transform: translateY(-50%) rotate(-15deg);
  transform-origin: top right;
}

.square-3 {
  top: calc(70% - 20px);
  right: 0;
  transform: translateY(-50%) rotate(15deg);
  transform-origin: top left;
  opacity: 0.5;
}

.square-4 {
  top: calc(70% - 20px);
  left: 0;
  transform: translateY(-50%) rotate(-15deg);
  transform-origin: top right;
  opacity: .5;
}

.rectangle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  opacity: 1;
  z-index: 1;
}

@media (max-width: 768px) {
  .contact-card {
    max-width: 90%;
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .contact-section {
    padding: 60px 0;
  }

  .contact-card {
    padding: 15px;
  }
}