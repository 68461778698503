.vertical-pad {
  width: 1px;
  height: var(--height);
}

@media (max-width: 1100px) {
  .vertical-pad {
    height: calc(var(--height) / 2);
  }
}

@media (max-width: 768px) {
  .vertical-pad {
    height: calc(var(--height) / 3);
  }
}