.message-container {
  overflow: hidden;
  position: relative;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-image: url('../../public/leaf-background.png');
  background-size: 150%;
  background-position: center center;
  background-repeat: no-repeat;
}

.message-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(90, 139, 93, 0.92);
  z-index: 0;
}

.message-bubble {
  padding: 40px 40px;
  border-radius: 30px;
  max-width: 80%;
  width: fit-content;
  margin: 20px 0;
  font-size: 24px;
  color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  text-align: left;
}

.message-bubble-user {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.7), white);
  margin-left: auto;
  border-bottom-right-radius: 0;
}

.message-bubble-ai {
  background: linear-gradient(to left, rgba(209, 219, 164, 0.7), #e1eBb4);
  margin-right: auto;
  border-bottom-left-radius: 0;
}

.message-bubble::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border: 20px solid transparent;
}

.message-bubble-user::after {
  right: -20px;
  border-left-color: white;
  border-bottom: 0;
  border-right: 0;
}

.message-bubble-ai::after {
  left: -20px;
  border-right-color: #e1eBb4;
  border-bottom: 0;
  border-left: 0;
}

@media (min-width: 768px) {
  .message-container {
    padding: 40px 10%;
  }

  .message-bubble {
    max-width: 70%;
  }

  .message-bubble-user {
    margin-right: -5%;
  }

  .message-bubble-ai {
    margin-left: -5%;
  }
}

@media (max-width: 767px) {
  .message-bubble {
    max-width: 90%;
    font-size: 16px;
    padding: 20px;
  }

  .message-bubble::after {
    border-width: 15px;
  }

  .message-bubble-user::after {
    right: -15px;
  }

  .message-bubble-ai::after {
    left: -15px;
  }
}

@media (max-width: 480px) {
  .message-bubble {
    max-width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}